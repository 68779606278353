tr,
td {
  border-style: none;
}

.bg-christ {
  background: #13386d;
  color: #c4dad8;
}
.bg-christ > div {
  font-weight: 500;
}
.christ-blue {
  color: #183276;
}
@media (max-width: 400px) {
  .media-query {
    display: none;
  }
  .fontControl {
    font-size: 15px;
  }
}

.nk-btn {
  letter-spacing: 0.05rem;
  font-family: Nunito;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  /* font-weight: 600; */
}
.nk-btn:hover {
  background-color: #f77140f4;
  color: black !important;
}

.nk-blue {
  color: #183276;
}
.nk-blue-bg {
  background-color: #13386d;
}

.nk-black {
  color: black;
}
.nk-black-bg {
  background-color: black;
}

.nk-red {
  color: #ea3230;
}
.nk-red-bg {
  background-color: #ea3230;
}

.nk-green {
  color: #2c9355;
}
.nk-yellow-bg {
  background-color: #f1b940f7;
}

.nk-yello {
  color: #ebd543;
}
.nk-yello-bg {
  background-color: #ebd543;
}
.textColorHour {
  color: black;
  font-weight: 600;
}

.nk-bg-1 {
  background-color: #000080;
}
.nk-bg-2 {
  background-color: #110101;
}
.nk-bg-3 {
  background-color: #f77748;
}
.nk-bg-4 {
  background-color: #4e2953;
}
.nk-bg-5 {
  background-color: #183276;
}
.nk-bg-6 {
  background-color: #1140dc;
}
.nk-bg-7 {
  background-color: #edb641;
}
.nk-bg-8 {
  background-color: #c85d20;
}
.nk-bg-9 {
  background-color: #2c9355;
}
.nk-bg-10 {
  background-color: #fa959a;
}
.nk-bg-11 {
  background-color: #5620c8;
}
.nk-bg-12 {
  background-color: #1d535a;
}
.nk-bg-13 {
  background-color: #1a685a;
}
.nk-bg-14 {
  background-color: #28814b;
}
.nk-bg-15 {
  background-color: #1a3fbb;
}
.nk-bg-16 {
  background-color: #182e67;
}
.nk-bg-17 {
  background-color: #3728d1;
}
.nk-bg-18 {
  background-color: #1a3fbb;
}

.nk-bg-19 {
  background-color: #4b1ab0;
}

.nk-bg-20 {
  background-color: #6a3dc9;
}

.nk-bg-21 {
  background-color: #724dbf;
}
.nk-bg-22 {
  background-color: #b1511b;
}
.nk-bg-23 {
  background-color: #b5683c;
}
.nk-bg-24 {
  background-color: #b67b59;
}
.nk-bg-25 {
  background-color: #713be3;
}
.nk-bg-26 {
  background-color: #8259d9;
}
.nk-bg-27 {
  background-color: #713be3;
}
.nk-bg-28 {
  background-color: #713be3;
}
.nk-bg-29 {
  background-color: #713be3;
}
.nk-bg-30 {
  background-color: #c27142;
}
.nk-bg-31 {
  background-color: #c87f55;
}
.nk-bg-32 {
  background-color: #db9268;
}
.nk-bg-33 {
  background-color: #13386d;
  /* #B5683C */
}
#dates {
  border-radius: 2%;
}
.AI,
.CaPaFoIm {
  margin-top: 23px !important;
  margin-inline: 23px !important;
}
.border-bottom-custom {
  border-bottom: 1px solid #fbfbfef0 !important;
  box-sizing: border-box !important;
  padding-inline: 2% !important;
  padding-block: 3% !important;
}

.pt-bold {
  font-weight: "bold";
  font-size: "50px";
}
@font-face {
  font-family: Caslon;
  src: url(../public/CaslonBold.ttf);
}
#key :hover {
  color: red;

  cursor: pointer;
}
/*
.card-title :hover {
	color: red;	
	
} */

h3 {
  font-family: myFirstFont;
  color: black;
}

.oman {
  width: 8rem;
}
.TimerDiv {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px),
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .TimerDiv{
      display:flex;
      flex-direction: row;
    }
    .img-fluid {
      width: 26%; 
      height: 10%; 
    }
  .oman {
    width: 10rem;
  }
 

  .no-mob {
    display: none;
  }
  .separate {
    display: flex;
    width: 600px;
    flex-direction: column;
    text-align: justify;
  }
}

.nk-shadow {
  box-shadow: 0.2rem 0.2rem gray;
}

/*justify content */

.text-justify {
  text-align: justify;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f3f1ff;
}

table.table-bordered > thead > tr > th,
table.table-bordered > tbody > tr > th,
table.table-bordered > tbody > tr > td {
  border: 2px solid black;
  padding: 10px;
}

/* Main button styles */
.btn-nk {
  background-color: var(--bg1);
  color: var(--fg1);
  display: grid;
  place-items: center;
  font: 1em/1.5 Nunito, sans-serif;

  --hue: 223;
  --bg1: hsl(var(--hue), 10%, 90%);
  --bg2: hsl(var(--hue), 10%, 80%);
  --fg1: hsl(var(--hue), 10%, 10%);
  --fg2: hsl(var(--hue), 10%, 20%);
  --primary1: hsl(var(--hue), 90%, 55%);
  --primary2: hsl(var(--hue), 90%, 45%);
  font-size: calc(20px + (40 - 20) * (100vw - 320px) / (1280 - 320));
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 1.5em;
  display: block;
  position: relative;
  transition: width 0.3s ease-in-out;
}
.btn-nk:not(:disabled):active {
  transform: translateY(0.1em);
}
.btn__text {
  background-color: var(--primary1);
  border-radius: inherit;
  color: hsl(0, 0%, 100%);
  display: inline-block;
  padding: 0.75em 1.5em;
  transition: background-color 0.15s linear, color 0.15s 0.3s ease-in-out;
  width: 100%;
}
.btn:not(:disabled):focus .btn__text,
.btn:not(:disabled):hover .btn__text {
  background-color: var(--primary2);
}
.btn__progress {
  overflow: visible;
  position: absolute;
  top: 0;
  left: 0;
  width: 3em;
  height: 3em;
  visibility: hidden;
}
.btn__progress-track {
  r: 12px;
  stroke: var(--bg2);
  stroke-width: 24;
}
.btn__progress-fill {
  stroke: var(--primary1);
  stroke-dashoffset: 125.66;
}
.btn__progress-check {
  stroke: hsl(0, 0%, 100%);
  stroke-dashoffset: 34;
}

/* Both states */
.btn--running,
.btn--done {
  outline: none;
  pointer-events: none;
  width: 3em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.btn--running .btn__text,
.btn--done .btn__text {
  color: transparent;
  transition: background-color 0.3s ease-in-out, visibility 0.3s steps(1);
}
.btn--running .btn__progress,
.btn--done .btn__progress {
  visibility: visible;
}

/* Running state */
.btn--running .btn__text {
  background-color: var(--bg2);
  visibility: hidden;
}
.btn--running .btn__progress {
  transition: visibility 0.3s 0.3s steps(1, start);
}
.btn--running .btn__progress-track {
  r: 20px;
  stroke-width: 8;
  transition: r 0.3s 0.3s ease-in-out, stroke-width 0.3s 0.3s ease-in-out;
}
.btn--running .btn__progress-fill {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 2s 0.6s linear;
}

/* Done state */
.btn--done .btn__progress-track {
  stroke: var(--primary1);
  transition: r 0.3s ease-in-out, stroke-width 0.3s ease-in-out;
}
.btn--done .btn__progress-check {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.3s 0.3s ease-out;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg1: hsl(var(--hue), 10%, 10%);
    --bg2: hsl(var(--hue), 10%, 20%);
    --fg1: hsl(var(--hue), 10%, 90%);
    --fg2: hsl(var(--hue), 10%, 80%);
  }
}
